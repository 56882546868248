import React from "react";

const Jobs = () => {
  return (
    <section
      id="experience"
      className="py-16 flex items-center justify-center relative overflow-hidden"
    >
      <div className="absolute top-0 left-0 w-full h-full z-10"></div>

      <div className="container mx-auto px-4 flex flex-col">
        <div
          style={{
            background: "linear-gradient(45deg, black, white)",
            padding: "10px",
          }}
        >
          <div className="p-8 bg-white rounded-lg shadow-lg relative">
            <h2 className="text-3xl font-bold mb-8 text-gray-900 text-center">
              Professional Experience
            </h2>
            <hr className="mb-8" />

            <div className="space-y-12">
              {/* IQUANTI Inc. */}
              <div className="p-8 bg-white rounded-lg shadow-lg relative overflow-hidden">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-gray-300 to-gray-100 opacity-25"
                  style={{ pointerEvents: "none" }}
                ></div>
                <div className="md:flex md:space-x-8 relative z-10">
                  <div className="md:w-1/3 mb-6 md:mb-0 flex items-center justify-center shadow-lg">
                    <img
                      src="/images/iquanti.png"
                      alt="IQUANTI Logo"
                      className="h-32 object-contain mx-auto"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    />
                  </div>

                  <div className="md:w-2/3">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">
                      Web Developer Intern - IQUANTI Inc.
                    </h3>
                    <p className="text-gray-700 mb-4">
                      <b>Projects:</b> American Express Global Consumer
                      Services, LAUREL ROAD, DIGIBANK by DBS, Global Hospitals,
                      ALPS new build (Jan 2021 to Jan 2022)
                    </p>
                    <p className="text-gray-700 mb-4">
                      <b>Role:</b> As a Web Developer Intern, I was involved in
                      developing web applications using CMS and frameworks,
                      enhancing customer digital presence, and driving product
                      development.
                    </p>
                    <ul className="list-disc list-inside space-y-2 text-gray-700">
                      <li>
                        Maintained WordPress multisite infrastructure for
                        American Express, managed resources, and tracked project
                        milestones.
                      </li>
                      <li>
                        Created landing pages for LAUREL ROAD, and took a
                        leading role in content maintenance and migration.
                      </li>
                      <li>
                        Led the ALPS project along with the Team Lead, managing
                        strategy, UX/UI design, and web team coordination.
                      </li>
                      <li>
                        Contributed to DIGIBANK by DBS's blog site Live More
                        Zone, focusing on UI/UX design and content management.
                      </li>
                      <li>
                        Developed multiple landing pages for Global Hospitals,
                        including theme creation for different regions.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Client Logos Carousel */}
              <div className="bg-white py-4 mt-8 rounded-lg shadow-md">
                <div className="container mx-auto px-4">
                  <h4 className="text-lg font-semibold text-center mb-4">
                    Clients Worked With
                  </h4>
                  <div className="overflow-hidden relative">
                    <div className="scroll-container">
                      <div className="scroll-content">
                        <img
                          src="/images/amex.png"
                          alt="American Express Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/laurelroad.png"
                          alt="Laurel Road Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/DBS.png"
                          alt="DBS Bank Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/gh.png"
                          alt="Global Hospitals Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/ALPS.png"
                          alt="ALPS Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                      </div>
                      <div className="scroll-content">
                        <img
                          src="/images/amex.png"
                          alt="American Express Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/laurelroad.png"
                          alt="Laurel Road Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/DBS.png"
                          alt="DBS Bank Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/gh.png"
                          alt="Global Hospitals Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/ALPS.png"
                          alt="ALPS Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .scroll-container {
          display: flex;
          width: calc(200%); /* Double the width to hold two sets of content */
          animation: scroll 20s linear infinite;
        }

        .scroll-content {
          display: flex;
          width: 50%; /* Each content set takes up half of the container */
        }

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        /* Adjust animation speed and spacing for different screen sizes */
        @media (max-width: 768px) {
          .scroll-container {
            animation: scroll 30s linear infinite;
          }
          .scroll-content img {
            width: 11%;
            height: 100%;
          }
        }

        @media (min-width: 768px) {
          .scroll-container {
            animation: scroll 25s linear infinite;
          }
          .scroll-content img {
            width: 100px;
            height: 80px;
          }
        }

        @media (min-width: 1024px) {
          .scroll-content img {
            width: 18%;
            height: 60%;
          }
        }
      `}</style>
    </section>
  );
};

export default Jobs;
