import React, { useEffect, useState, useRef } from "react";

const About = () => {
  const [isVisible, setIsVisible] = useState(true);
  const aboutRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    const currentRef = aboutRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      id="about"
      ref={aboutRef}
      className="min-h-screen py-16 flex items-center justify-center relative overflow-hidden"
    >
      <div className="absolute top-0 left-0 w-full h-full z-10"></div>

      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center relative z-20">
        <div
          className="relative p-1 rounded-lg"
          style={{
            background: "linear-gradient(45deg, black, white)",
            padding: "5px",
            marginBottom: "15%",
          }}
        >
          <div
            className="p-8 rounded-lg shadow-lg bg-white relative"
            style={{ borderRadius: "inherit" }}
          >
            <div>
              <h2 className="text-3xl font-bold mb-4 text-gray-900">
                About Me
              </h2>
              <hr className="mb-8" />
              <p className="text-lg text-gray-700 leading-relaxed">
                I’m a Web Developer having a year of experience working with
                Realtime projects. I’m skilled in many front-end and back-end
                technologies like PHP, JavaScript, node.js, React.js, and Java,
                with a strong foundation in SQL and database design. Proficient
                in Agile and Waterfall methodologies, adept at managing web
                projects for prestigious clients like American Express
                Insurance, LAUREL ROAD, DIGIBANK by DBS, and Global Hospitals
                during my placement year. Currently I’m expanding my skills
                through a Web Development Graduate Certificate, alongside a
                Master's in Advanced Computer Science with Industry and a
                Bachelor's in Computer Science and Engineering.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
